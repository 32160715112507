import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Props {
  business: any;
}

const initialState: Props = {
  business: null,
};

export const businessSlice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    setBusiness: (state: Props, action: PayloadAction<any>) => {
      state.business = action.payload.business;
    },
  }
});

// Action creators are generated for each case reducer function
export const {
  setBusiness,
} = businessSlice.actions;

export default businessSlice.reducer;
