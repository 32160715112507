import { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import store from './store';
// import store from './redux/store';
import reportWebVitals from './reportWebVitals';

const App = lazy(() => import(/* webpackChunkName: "App" */ './App'));

const persistor = persistStore(store);

const Main = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Suspense fallback={<div className="loading" />}>
        <App />
      </Suspense>
    </PersistGate>
  </Provider>
);

ReactDOM.render(<Main />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
