import { combineReducers } from '@reduxjs/toolkit';
import auth from './slices/auth';
import business from './slices/business';
import settings from './slices/settings';
import menu from './slices/menu';

export const rootReducer = combineReducers({
  auth,
  business,
  settings,
  menu,
});
