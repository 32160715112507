import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  defaultMenuType,
  subHiddenBreakpoint,
  menuHiddenBreakpoint,
} from '../../../constants/defaultValues';

interface Props {
  containerClassnames: string;
  subHiddenBreakpoint: number;
  menuHiddenBreakpoint: number;
  menuClickCount: number;
  selectedMenuHasSubItems: boolean;
}

const initialState: Props = {
  containerClassnames: defaultMenuType,
  subHiddenBreakpoint,
  menuHiddenBreakpoint,
  menuClickCount: 0,
  selectedMenuHasSubItems: defaultMenuType === 'menu-default', // if you use menu-sub-hidden as default menu type, set value of this variable to false
};

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    changeSelectedMenuHasSubItems: (state: Props, action: PayloadAction<any>) => {
      state.selectedMenuHasSubItems = action.payload;
    },
    changeDefaultClassnames: (state: Props, action: PayloadAction<any>) => {
      state.containerClassnames = action.payload;
    },
    addContainerClassname: (state: Props, { payload }: PayloadAction<any>) => {
      const { strCurrentClasses, classname } = payload;
      const newClasses = !(strCurrentClasses.indexOf(classname) > -1)
        ? `${strCurrentClasses} ${classname}`
        : strCurrentClasses;
      state.containerClassnames = newClasses;
    },
    clickOnMobileMenu: (state: Props, action: PayloadAction<any>) => {
      const currentClasses = action.payload
        ? action.payload
          .split(' ')
          .filter((x: any) => x !== '' && x !== 'sub-show-temporary')
        : '';
      let nextClasses = '';
      if (currentClasses.includes('main-show-temporary')) {
        nextClasses = currentClasses
          .filter((x: any) => x !== 'main-show-temporary')
          .join(' ');
      } else {
        nextClasses = `${currentClasses.join(' ')} main-show-temporary`;
      }
      state.containerClassnames = nextClasses;
      state.menuClickCount = 0;
    },
    setContainerClassnames: (state: Props, { payload }: PayloadAction<any>) => {
      const currentClasses = payload.strCurrentClasses
        ? payload.strCurrentClasses.split(' ').filter((x: any) => x !== '')
        : '';
      let nextClasses = '';
      if (!payload.selectedMenuHasSubItems) {
        if (
          currentClasses.includes('menu-default') && (payload.clickIndex % 4 === 0 || payload.clickIndex % 4 === 3)
        ) {
          payload.clickIndex = 1;
        }
        if (currentClasses.includes('menu-sub-hidden') && payload.clickIndex % 4 === 2) {
          payload.clickIndex = 0;
        }
        if (
          currentClasses.includes('menu-hidden') && (payload.clickIndex % 4 === 2 || payload.clickIndex % 4 === 3)
        ) {
          payload.clickIndex = 0;
        }
      }

      if (payload.clickIndex % 4 === 0) {
        if (
          currentClasses.includes('menu-default') && currentClasses.includes('menu-sub-hidden')
        ) {
          nextClasses = 'menu-default menu-sub-hidden';
        } else if (currentClasses.includes('menu-default')) {
          nextClasses = 'menu-default';
        } else if (currentClasses.includes('menu-sub-hidden')) {
          nextClasses = 'menu-sub-hidden';
        } else if (currentClasses.includes('menu-hidden')) {
          nextClasses = 'menu-hidden';
        }
        payload.clickIndex = 0;
      } else if (payload.clickIndex % 4 === 1) {
        if (
          currentClasses.includes('menu-default') && currentClasses.includes('menu-sub-hidden')
        ) {
          nextClasses = 'menu-default menu-sub-hidden main-hidden sub-hidden';
        } else if (currentClasses.includes('menu-default')) {
          nextClasses = 'menu-default sub-hidden';
        } else if (currentClasses.includes('menu-sub-hidden')) {
          nextClasses = 'menu-sub-hidden main-hidden sub-hidden';
        } else if (currentClasses.includes('menu-hidden')) {
          nextClasses = 'menu-hidden main-show-temporary';
        }
      } else if (payload.clickIndex % 4 === 2) {
        if (
          currentClasses.includes('menu-default') && currentClasses.includes('menu-sub-hidden')
        ) {
          nextClasses = 'menu-default menu-sub-hidden sub-hidden';
        } else if (currentClasses.includes('menu-default')) {
          nextClasses = 'menu-default main-hidden sub-hidden';
        } else if (currentClasses.includes('menu-sub-hidden')) {
          nextClasses = 'menu-sub-hidden sub-hidden';
        } else if (currentClasses.includes('menu-hidden')) {
          nextClasses = 'menu-hidden main-show-temporary sub-show-temporary';
        }
      } else if (payload.clickIndex % 4 === 3) {
        if (
          currentClasses.includes('menu-default') && currentClasses.includes('menu-sub-hidden')
        ) {
          nextClasses = 'menu-default menu-sub-hidden sub-show-temporary';
        } else if (currentClasses.includes('menu-default')) {
          nextClasses = 'menu-default sub-hidden';
        } else if (currentClasses.includes('menu-sub-hidden')) {
          nextClasses = 'menu-sub-hidden sub-show-temporary';
        } else if (currentClasses.includes('menu-hidden')) {
          nextClasses = 'menu-hidden main-show-temporary';
        }
      }
      if (currentClasses.includes('menu-mobile')) {
        nextClasses += ' menu-mobile';
      }
      state.containerClassnames = nextClasses;
      state.menuClickCount = payload.clickIndex;
    },
  }
});

// Action creators are generated for each case reducer function
export const {
  changeSelectedMenuHasSubItems,
  changeDefaultClassnames,
  addContainerClassname,
  clickOnMobileMenu,
  setContainerClassnames,
} = menuSlice.actions;

export default menuSlice.reducer;
