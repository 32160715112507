import {
  defaultDirection,
  defaultLocale,
  defaultColor,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey,
} from '../constants/defaultValues';

export const mapOrder = (array, order, key) => {
  // eslint-disable-next-line func-names
  array.sort((a, b) => {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction');
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    console.warn('>>>>: src/helpers/Utils.js : getDirection -> error', error);
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};
export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  try {
    localStorage.setItem('direction', direction);
  } catch (error) {
    console.warn('>>>>: src/helpers/Utils.js : setDirection -> error', error);
  }
};

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey);
    }
  } catch (error) {
    console.warn(
      '>>>>: src/helpers/Utils.js : getCurrentColor -> error',
      error,
    );
    currentColor = defaultColor;
  }
  return currentColor;
};

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, color);
  } catch (error) {
    console.warn(
      '>>>>: src/helpers/Utils.js : setCurrentColor -> error',
      error,
    );
  }
};

export const getCurrentRadius = () => {
  let currentRadius = 'rounded';
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    console.warn(
      '>>>>: src/helpers/Utils.js : getCurrentRadius -> error',
      error,
    );
    currentRadius = 'rounded';
  }
  return currentRadius;
};
export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {
    console.warn(
      '>>>>: src/helpers/Utils.js : setCurrentRadius -> error',
      error,
    );
  }
};

export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language = localeOptions.filter(
      (x) => x.id === localStorage.getItem('currentLanguage'),
    ).length > 0
      ? localStorage.getItem('currentLanguage')
      : defaultLocale;
    // language = localStorage.getItem('currentLanguage');
    // localeOptions.filter(
    //   (x) => x.id === localStorage.getItem('currentLanguage')
    // ).length > 0
    //   ? localStorage.getItem('currentLanguage')
    //   : defaultLocale;
  } catch (error) {
    console.warn(
      '>>>>: src/helpers/Utils.js : getCurrentLanguage -> error',
      error,
    );
    language = defaultLocale;
  }
  return language;
};
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('currentLanguage', locale);
  } catch (error) {
    console.warn(
      '>>>>: src/helpers/Utils.js : setCurrentLanguage -> error',
      error,
    );
  }
};

export const getCurrentUser = () => {
  let user = null;
  try {
    user = localStorage.getItem('current_user') != null
      ? JSON.parse(localStorage.getItem('current_user'))
      : null;
  } catch (error) {
    console.warn(
      '>>>>: src/helpers/Utils.js  : getCurrentUser -> error',
      error,
    );
    user = null;
  }
  return user;
};

export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem('current_user', JSON.stringify(user));
      localStorage.removeItem('current_user_without_business');
    } else {
      localStorage.removeItem('current_user');
    }
  } catch (error) {
    console.warn('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
  }
};

export const getCurrentUserWithoutBusiness = () => {
  let user = null;
  try {
    user = localStorage.getItem('current_user_without_business') != null
      ? JSON.parse(localStorage.getItem('current_user_without_business'))
      : null;
  } catch (error) {
    console.warn(
      '>>>>: src/helpers/Utils.js  : getCurrentUserWithoutBusiness -> error',
      error,
    );
    user = null;
  }
  return user;
};

export const setCurrentUserWithoutBusiness = (user) => {
  try {
    if (user) {
      localStorage.setItem(
        'current_user_without_business',
        JSON.stringify(user),
      );
      localStorage.removeItem('current_user');
    } else {
      localStorage.removeItem('current_user_without_business');
    }
  } catch (error) {
    console.warn(
      '>>>>: src/helpers/Utils.js : setCurrentUserWithoutBusiness -> error',
      error,
    );
  }
};

export const getAcceptsCookies = () => {
  let boolean = false;
  try {
    boolean = localStorage.getItem('accepts_cookies') !== null
      ? JSON.parse(localStorage.getItem('accepts_cookies'))
      : false;
  } catch (error) {
    console.warn(
      '>>>>: src/helpers/Utils.js  : getAcceptsCookies -> error',
      error,
    );
  }
  return boolean;
};

export const setAcceptsCookies = (boolean) => {
  try {
    if (typeof boolean === 'boolean') {
      localStorage.setItem('accepts_cookies', JSON.stringify(boolean));
    } else {
      localStorage.removeItem('accepts_cookies');
    }
  } catch (error) {
    console.warn(
      '>>>>: src/helpers/Utils.js  : setAcceptsCookies -> error',
      error,
    );
  }
};

export const getAccessToken = () => {
  let token = null;
  try {
    token = localStorage.getItem('jwtat') !== null
      ? JSON.parse(localStorage.getItem('jwtat'))
      : null;
  } catch (error) {
    console.warn(
      '>>>>: src/helpers/Utils.js  : getAccessToken -> error',
      error,
    );
  }
  return token;
};

export const setAccessToken = (token) => {
  try {
    if (token) {
      localStorage.setItem('jwtat', JSON.stringify(token));
    } else {
      localStorage.removeItem('jwtat');
    }
  } catch (error) {
    console.warn(
      '>>>>: src/helpers/Utils.js  : setAccessToken -> error',
      error,
    );
  }
};

export const formatPrice = (price) => {
  const numberFormat = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
  });
  return numberFormat.format(price);
};
