import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getCurrentUser,
  getCurrentUserWithoutBusiness,
  setAccessToken,
  setCurrentUser,
  // setCurrentUserWithoutBusiness,
} from '../../../helpers/Utils';
import { isAuthGuardActive, testUser } from '../../../constants/defaultValues';

interface Props {
  currentUser: any;
  currentUserWithoutBusiness: any;
  forgotUserMail: string;
  newPassword: string;
  resetPasswordCode: string;
  loading: boolean;
  error: string;
}

const initialState: Props = {
  currentUser: isAuthGuardActive ? getCurrentUser() : testUser,
  currentUserWithoutBusiness: isAuthGuardActive ? getCurrentUserWithoutBusiness() : null,
  // currentUser: null,
  // currentUserWithoutBusiness: null,
  forgotUserMail: '',
  newPassword: '',
  resetPasswordCode: '',
  loading: false,
  error: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    startFetch: (state: Props) => {
      state.loading = true;
      state.error = '';
    },
    cleanError: (state: Props) => {
      state.error = '';
    },
    loginUserSuccess: (state: Props, { payload }: PayloadAction<any>) => {
      const {
        _id,
        // userType, //
        firstName,
        lastName,
        businessId,
        credit,
        email,
        isEmailVerified,
        hasMultipleEstablishments,
        defaultEstablishment,
        establishmentsId = [],
        showOperatorOnboarding,
        showFinishedOnboarding
      } = payload;
      const user: any = {
        _id,
        firstName,
        lastName,
        credit,
        email,
        hasMultipleEstablishments,
        defaultEstablishment,
        showOperatorOnboarding,
        // role: userType,
        role: 'admin',
        showFinishedOnboarding
      };
      state.loading = false;
      state.error = '';
      payload.role = payload.userType;
      if (establishmentsId.length === 0) {
        user.isEmailVerified = isEmailVerified;
        // state.currentUserWithoutBusiness = user;
        state.currentUser = user;
        setCurrentUser(user);
      } else {
        user.businessId = businessId;
        state.currentUser = user;
        setCurrentUser(user);
      }
    },
    fetchAdminData: (state: Props, { payload }: PayloadAction<any>) => {
      const {
        _id,
        // userType, //
        firstName,
        lastName,
        businessId,
        credit,
        email,
        isEmailVerified,
        hasMultipleEstablishments,
        defaultEstablishment,
        establishmentsId = [],
        showOperatorOnboarding,
        showFinishedOnboarding
      } = payload;
      const user: any = {
        _id,
        firstName,
        lastName,
        email,
        // role: userType,
        role: 'admin',
        showOperatorOnboarding,
        showFinishedOnboarding,
      };
      state.loading = false;
      state.error = '';
      user.businessId = businessId;
      // payload.role = payload.userType;
      if (establishmentsId.length === 0) {
        user.isEmailVerified = isEmailVerified;
        state.currentUser = null;
        // state.currentUserWithoutBusiness = user;
        state.currentUser = user;
        setCurrentUser(user);
      } else {
        user.credit = credit;
        user.hasMultipleEstablishments = hasMultipleEstablishments;
        user.defaultEstablishment = defaultEstablishment;
        state.currentUser = user;
        state.currentUserWithoutBusiness = null;
        setCurrentUser(user);
      }
    },
    loginUserError: (state: Props, action: PayloadAction<any>) => {
      state.loading = false;
      state.currentUser = null;
      state.currentUserWithoutBusiness = null;
      state.error = action.payload;
    },
    fetchAdminDataError: (state: Props, action: PayloadAction<any>) => {
      if (action.payload === 'Unauthorized') {
        setCurrentUser();
        setCurrentUser();
        setAccessToken();
      }
      state.loading = false;
      state.currentUser = null;
      state.currentUserWithoutBusiness = null;
      state.error = action.payload;
    },
    logoutUser: (state: Props, action: PayloadAction<any>) => {
      // Resets localStorage
      setCurrentUser();
      setCurrentUser();
      setAccessToken();
      state.currentUser = null;
      state.currentUserWithoutBusiness = null;
    },
    registerUserSuccess: (state: Props, { payload }: PayloadAction<any>) => {
      const {
        _id,
        // userType,
        firstName,
        lastName,
        email,
        isEmailVerified,
        // establishmentsId,
        showOperatorOnboarding,
        showFinishedOnboarding,
      } = payload;
      const user = {
        _id,
        firstName,
        lastName,
        // role: userType,
        email,
        isEmailVerified,
        // establishmentsId,
        showOperatorOnboarding,
        showFinishedOnboarding
      };
      // setCurrentUser(user);
      setCurrentUser(user);
      state.loading = false;
      state.error = '';
      // state.currentUserWithoutBusiness = user;
      state.currentUser = user;
    },
    registerUserError: (state: Props, action: PayloadAction<any>) => {
      state.loading = false;
      state.currentUser = null;
      state.currentUserWithoutBusiness = null;
      state.error = action.payload;
    },
    registerEstablishmentSuccess: (state: Props, { payload }: PayloadAction<any>) => {
      const {
        _id,
        // userType, //
        firstName,
        lastName,
        businessId,
        credit = 0,
        hasMultipleEstablishments,
        defaultEstablishment,
        showOperatorOnboarding = true,
        showFinishedOnboarding,
      } = payload;
      const user = {
        _id,
        firstName,
        lastName,
        role: 'admin',
        businessId,
        credit,
        hasMultipleEstablishments,
        defaultEstablishment,
        showOperatorOnboarding,
        showFinishedOnboarding,
      };
      setCurrentUser(user);
      setCurrentUser();
      state.loading = false;
      state.currentUser = user;
      state.currentUserWithoutBusiness = null;
      state.error = '';
    },
    registerEstablishmentError: (state: Props, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
    },
    forgotPasswordSuccess: (state: Props, action: PayloadAction<any>) => {
      state.loading = false;
      state.forgotUserMail = action.payload;
      state.error = '';
    },
    forgotPasswordError: (state: Props, action: PayloadAction<any>) => {
      state.loading = false;
      state.forgotUserMail = '';
      state.error = action.payload;
    },
    resetPasswordSuccess: (state: Props, action: PayloadAction<any>) => {
      state.loading = false;
      state.newPassword = action.payload;
      state.error = '';
    },
    resetPasswordError: (state: Props, action: PayloadAction<any>) => {
      state.loading = false;
      state.newPassword = '';
      state.error = action.payload;
    },
    updateCurrentUserSuccess: (state: Props, { payload }: PayloadAction<any>) => {
      state.loading = false;
      const user = {
        ...state.currentUser,
        ...payload,
      };
      state.currentUser = user;
      setCurrentUser(user);
      state.error = '';
    },
    updateCurrentUserError: (state: Props, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
    },
    showOperatorOnboardingInFalse: (state: Props) => {
      state.currentUser.showOperatorOnboarding = false;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  startFetch,
  cleanError,
  loginUserSuccess,
  fetchAdminData,
  loginUserError,
  fetchAdminDataError,
  logoutUser,
  registerUserSuccess,
  registerUserError,
  registerEstablishmentSuccess,
  registerEstablishmentError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
  updateCurrentUserSuccess,
  updateCurrentUserError,
  showOperatorOnboardingInFalse,
} = authSlice.actions;

export default authSlice.reducer;
